import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/atoms/seo"
import Layout from "../components/layout"
import PageTitle from "../components/atoms/page-title/page-title"
import ContactForm from "../components/molecules/contact-form"
import "./contact.scss"

const ContactPage = () => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query {
        allWordpressPage(filter: { title: { eq: "contact" } }) {
          edges {
            node {
              id
              title
              content
            }
          }
        }
      }
    `
  )
  const src =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.656710537334!2d2.1987906156752755!3d48.88382047928995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6649752fb7409%3A0xee1fe2fff348ff96!2s64%20Rue%20de%20Saint-Cloud%2C%2092000%20Nanterre!5e0!3m2!1sfr!2sfr!4v1593470174240!5m2!1sfr!2sfr"
  const width = "600"
  const height = "450"
  const frameBorder = "0"

  return (
    <Layout>
      <SEO title="Mopo Avocats Contact" />
      <PageTitle title="nous contacter" />
      <section className="section">
        <div className="content columns">
          <div className="contact-left column is-half-desktop">
            <ContactForm />
          </div>
          <div className="contact-right column is-half-desktop">
            <iframe
              src={src}
              width={width}
              height={height}
              frameBorder={frameBorder}
              style={{ border: 0 }}
            ></iframe>
            <div className="contact card">
              <div className="card-content">
                <div className="content">
                  {allWordpressPage.edges[0]?.node.content && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: allWordpressPage.edges[0]?.node.content,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
