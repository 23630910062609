import React, { useState, createRef } from "react"
import Recaptcha from "react-google-recaptcha"
import emailjs from "emailjs-com"

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === "undefined") {
  throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `)
}
const SERVICE_ID = process.env.GATSBY_APP_SITE_EMAILJS_SERVICE_ID
if (typeof SERVICE_ID === "undefined") {
  throw new Error("Env var GATSBY_APP_SITE_EMAILJS_SERVICE_ID is undefined!")
}
const TEMPLATE_ID = process.env.GATSBY_APP_SITE_EMAILJS_TEMPLATE_ID
if (typeof TEMPLATE_ID === "undefined") {
  throw new Error("Env var GATSBY_APP_SITE_EMAILJS_TEMPLATE_ID is undefined!")
}
const USER_ID = process.env.GATSBY_APP_SITE_EMAILJS_USER_ID
if (typeof USER_ID === "undefined") {
  throw new Error("Env var GATSBY_APP_SITE_EMAILJS_USER_ID is undefined!")
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  const [state, setState] = useState({})
  const recaptchaRef = createRef()

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const emailjsPost = () => {
    const templateParams = state
    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID).then(
      response => {
        console.log("SUCCESS!", response.status, response.text)
      },
      err => {
        console.log("FAILED...", err)
      }
    )
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...state,
      }),
    })
      .then(() => emailjsPost())
      .catch(error => alert(error))
  }

  return (
    <form
      name="contact-recaptcha"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-recaptcha="true"
      onSubmit={handleSubmit}
    >
      <noscript>
        <p>This form won’t work with Javascript disabled</p>
      </noscript>
      <div className="field">
        <label className="label">Question:</label>
        <div className="control">
          <textarea
            name="question"
            className="textarea"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Nom:</label>
        <div className="control">
          <input
            type="text"
            name="lastName"
            className="input"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Prénom:</label>
        <div className="control">
          <input
            type="text"
            name="firstName"
            className="input"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Société: </label>
        <div className="control">
          <input
            type="text"
            name="company"
            className="input"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Téléphone: </label>
        <div className="control">
          <input
            type="text"
            name="phone"
            className="input"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Email:</label>
        <div className="control">
          <input
            type="email"
            name="email"
            className="input"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="field">
        <input
          type="checkbox"
          name="optin"
          value="Oui"
          onChange={handleChange}
          className="contact-input checkbox"
        />
        <label className="contact-label checkbox">
          Je souhaite recevoir la lettre d&#39;information du
          cabinet&nbsp;&nbsp;
        </label>

        <button type="submit" className="contact-send button">
          Envoyer
        </button>
      </div>
      <div>
        <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
      </div>
    </form>
  )
}

export default ContactForm
